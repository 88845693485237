<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-12 col-md-4">
        <empresa-combo
          :selected="$store.state.auth.user.cod_empresa"
          :selecionar="selecionarEmpresa"
          :no-style="true"
        />
      </div>
      <div class="col-6 col-md-4 p-0 pr-1 d-flex">
        <b-input
          type="number"
          pattern="\d*"
          name="senhaFilter"
          autocomplete="off"
          v-model="filtro_senha"
          placeholder="SENHA"
          debounce="200"
        />
        <div class="pl-3 pr-3 pt-1">OU</div>
        <b-input
          type="text"
          name="generalFilter"
          autocomplete="off"
          v-model="filtro"
          placeholder="cliente, bairro..."
          debounce="200"
        />
      </div>
      <div class="col-6 col-md-4 p-0">
        <b-input
          type="date"
          class="text-center"
          ref="txtDT"
          v-model="data"
        />
      </div>
      <div class="col-12 mt-2 text-center scollBorda">
        <div class="row">
          <div class="col-12 col-md-auto p-0 pl-1 pr-1">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="tipo_entrega_filtro"
                :options="options_entrega.slice(0, 3)"
                :aria-describedby="ariaDescribedby"
                name="buttons-1"
                button-variant="outline-primary"
                buttons
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-auto p-0 pl-1 pr-1">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="tipo_entrega_filtro"
                :options="options_entrega.slice(3, 5)"
                :aria-describedby="ariaDescribedby"
                name="buttons-1"
                button-variant="outline-primary"
                buttons
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-auto p-0 pl-1 pr-1">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="tipo_entrega_filtro"
                :options="options_entrega.slice(5, 8)"
                :aria-describedby="ariaDescribedby"
                name="buttons-2"
                button-variant="outline-primary"
                buttons
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-auto p-0 pl-1 pr-1 text-left">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="tipo_entrega_filtro"
                :options="options_entrega.slice(8, 11)"
                :aria-describedby="ariaDescribedby"
                name="buttons-3"
                button-variant="outline-primary"
                buttons
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-auto p-0 pl-1 pr-1 text-right">
            <b-checkbox
              button
              v-model="filtro_agendamentos"
              :button-variant="
                filtro_agendamentos === true ? 'info' : 'secondary'
              "
            >
              <b-icon-calendar-x v-if="!filtro_agendamentos" />
              <b-icon-calendar-check v-if="filtro_agendamentos" />
              Visualizar Agendamentos
            </b-checkbox>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-6">
            <div class="form-inline">
              <button class="btn btn-primary btn-sm"><i class="fa fa-hands"></i> Balcão</button>
              <b-check switch><i class="fa fa-store"></i> Buscar</b-check>
              <b-check switch><i class="fa fa-hands"></i> Balcão</b-check>
             </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6 text-left">
        <i
          class="fa fa-filter mr-2"
          style="float: left"
        />
        <div
          class="filtroSelect"
          @click="$refs.txtDT.focus()"
        >
          <i class="fa fa-calendar mr-2" /> {{ data | moment("DD/MM/YYYY") }}
        </div>
        <div
          class="filtroSelect"
          v-if="filtro != ''"
          @click="filtro = ''"
        >
          <i class="fa fa-times" /> {{ filtro }}
        </div>
        <div
          class="filtroSelect"
          v-if="filtro_senha != ''"
          @click="filtro_senha = ''"
        >
          <i class="fa fa-times" /> {{ filtro_senha }}
        </div>
        <div
          @click="
            tipo_entrega_filtro = tipo_entrega_filtro.filter((x) => x != fil)
          "
          v-for="fil in tipo_entrega_filtro"
          :key="fil.v"
          class="filtroSelect"
        >
          <i class="fa fa-times" />
          {{
            fil.tp == "ent"
              ? fil.v
              : fil.tp == "prob"
              ? "Problema Entrega"
              : fil.tp == "pgto"
              ? "Sem Pagamento"
              : fil.tp == "online"
              ? "Online"
              : fil.tp == "ifood"
              ? "Ifood"
              : fil.tp == "tipo_entrega"
              ? fil.v == "0"
                ? "Pendentes"
                : fil.v == "3"
                ? "Á Confirmar"
                : fil.v == "1"
                ? "Em Rotas"
                : "Concluídos"
              : fil
          }}
        </div>
      </div>
      <div class="col-6 text-right">
        <div class="float-right">
          <b-select
            :options="orderOptions"
            v-model="orderSelected"
          />
        </div>
        <b-button
          variant="danger"
          @click="$bvModal.show('modal-pedido-online')"
        >
          <i class="fa fa-globe" /> Pedido Online
        </b-button>
        <b-button
          variant="primary"
          @click="carregar()"
        >
          <i class="fa fa-sync" /> atualizar
        </b-button>
      </div>

      <!-- <div class="col-12">
        <div class="row">
          <div class="col-12" v-for="ped of pedidos" :key="ped.cod_pedido">
            {{ ped.cod_pedido }}
          </div>
        </div>
      </div> -->
      <div
        v-if="loading"
        class="text-center p-5 w-100"
      >
        <b-spinner />
        Carregando...
      </div>
      <div
        class="col-12"
        v-if="pedidosAgendadoPaginado && pedidosAgendadoPaginado.length > 0"
      >
        <div class="agendamentos m-2">
          <h4><b-icon-calendar-date /> Pedidos Agendados</h4>
          <hr />
          <div
            v-for="pedido in pedidosAgendadoPaginado"
            :key="'peddido' + pedido.cod_pedido"
          >
            <pedido-card
              :pedido="pedido"
              :confirmarPedido="confirmarPedido"
              :receber="receber"
              :abrirPedido="abrirPedido"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div
          v-for="pedido in pedidosPaginado"
          :key="'peddido' + pedido.cod_pedido"
        >
          <pedido-card
            :pedido="pedido"
            :confirmarPedido="confirmarPedido"
            :receber="receber"
            :abrirPedido="abrirPedido"
            @atualizarPedidos="carregar"
          />
        </div>
        <!-- <b-table
          class="tbPeds "
          :items="pedidos"
          :fields="fields"
          responsive
          :filter="filtro"
          :per-page="paginacao.registros"
          :current-page="paginacao.pagina"
          :empty-text="`Nenhum registro encontrado`"
          :empty-filtered-text="`Nenhum registro encontrado`"
          :busy="loading"
          hover
          small
          show-empty
          selectable
          no-border-collapse
          selected-variant
          select-mode="single"
          style="font-size: 12px"
          sort-by="pagamento"
          @row-selected="abrirPedido"
          rel="tbCaixas"
          @hidden="closeModalEdit"
        >
          <template #table-busy>
            <div class="text-left p-3">
              <b-spinner small></b-spinner> carregando...
            </div>
          </template>
          <template #cell(data)="row">
            {{ row.item.data | moment("HH:mm") }}
          </template>
          <template #cell(senha)="row">
            <div class="" style="font-weight: bold; font-size: 1rem">
              {{ row.item.senha }}
            </div>
            <div style="font-size: 0.5rem">#{{ row.item.cod_pedido }}</div>
          </template>
          <template #cell(tipo_entrega)="row">
            {{ row.item.tipo_entrega }}
            <br />
            <small
              ><small>{{
                row.item.usuario_cadastro ? row.item.usuario_cadastro.nome : ""
              }}</small></small
            >
          </template>
          <template #cell(sistema)="row">
            {{
              row.item.sistema.indexOf("-") > 0
                ? row.item.sistema.split("-")[1]
                : row.item.sistema
            }}
            <span v-if="row.item.id_sistema">
              <br />
              <small>{{ row.item.id_sistema }}</small>
            </span>
          </template>
          <template #cell(cliente.Nome)="row">
            <div class="text-left">
              {{
                row.item.cliente && row.item.cliente.Nome
                  ? row.item.cliente.Nome
                  : ""
              }}
            </div>
            <div v-if="row.item.sistema == 'ONPEDIDO' && row.item.status == 0">
              <b-button variant="info" @click="confirmarPedido(row.item)"
                ><i class="fa fa-check"></i> CONFIRMAR PEDIDO</b-button
              >
            </div>
          </template>

          <template #cell(pagamento)="row">
            <div class="text-center">
              <div v-if="row.item.pagamento && row.item.pagamento != ''">
                {{ row.item.pagamento }}
                <br />
                <small v-if="row.item.usuario_recebeu"
                  ><small
                    >{{
                      row.item.usuario_recebeu
                        ? row.item.usuario_recebeu.nome
                        : ""
                    }}
                  </small></small
                >
              </div>
              <div v-else>
                <b-button variant="success" @click="receber(row.item)"
                  ><i class="fa fa-money-bill-alt"></i> Receber</b-button
                >
              </div>
            </div>
          </template>

          <template #cell(vl_total)="row">
            <div class="text-center">
              {{ row.item.vl_total | currency }}
            </div>
          </template>
        </b-table> -->
      </div>
      <div class="col-12 col-sm-6">
        <b-pagination
          v-model="paginacao.pagina"
          :total-rows="pedidos.length"
          :per-page="paginacao.registros"
          aria-controls="my-table"
        />
        <div
          class="text-left"
          style="font-size: 11px"
        />
      </div>
      <div class="col-12 col-sm-6 text-right">
        <small>Total de Pedidos: {{ pedidos.length }}</small> <br />
        <small
          ><small
            >Ult. Atu. {{ ultUpd | moment("DD/MM/YYYY HH:mm:ss") }}</small
          ></small
        >
        <br />
        <small class=""
          ><label><i class="fa fa-cogs" /> Registros por Página</label>
          <br />
          <b-select
            :options="[10, 20, 30, 40, 50]"
            v-model="paginacao.registros"
            label="Registros/pagina"
            style="font-size: 11px; max-width: 60px"
        /></small>
      </div>
      <div class="col-12">
        <label class="block font-bold">Legendas</label>
        <div>
          <div class="legenda_box bg-danger">Á Confirmar</div>
          <div class="legenda_box bg-warning">Pendente - Não Pago</div>
          <div class="legenda_box bg-info">Rota entrega</div>
          <div class="legenda_box bg-success">Concluído / Entregue</div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-pedido"
      size="md"
      :content-class="!resumed ? 'prodModal' : ''"
      body-class="insideModal p-0 pt-2"
      no-fade
      no-footer
      hide-footer
      class="prodModal"
      @hidden="
        resumed = false;
        pedido = null;
      "
      :title="`Pedido ${
        pedido ? pedido.senha + ' - ' + pedido.cod_pedido : ''
      }`"
    >
      <pedido
        :pedido-open="pedido"
        :resumed="resumed"
        :close-on-save="CloseOnSave"
      />
    </b-modal>
    <b-modal
      size="xl"
      id="modal-pedido-online"
      title="Pedido Online"
      hide-footer
      @hidden="pedido = null"
    >
      <pedido-online
        :pedido-open="pedido"
        @closeModal="
          () => {
            this.carregar();
            this.$bvModal.hide('modal-pedido-online');
          }
        "
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import PedidoLib from "@/libs/Pedido";
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
import Pedido from "../../components/pedido/Pedido.vue";
import PedidoOnline from "../../components/pedido/PedidoOnline.vue";
import PedidoCard from "./PedidoCard.vue";
export default {
  components: { EmpresaCombo, Pedido, PedidoOnline, PedidoCard },
  data() {
    return {
      filtro_agendamentos: true,
      resumed: false,
      ultUpd: null,
      timer: null,
      pedido: null,
      data: moment().format("YYYY-MM-DD"),
      filtro: "",
      filtro_senha: "",
      pedidos: [],
      pedidosPaginado: [],
      pedidosAgendadoPaginado: [],
      peds: [],
      loading: false,
      empresa_selecionada: null,
      paginacao: {
        registros: 10,
        pagina: 1,
      },
      tipo_entrega_filtro: [],
      orderSelected: "0",
      orderOptions: [
        { value: "0", text: "Ordem" },
        { value: "senha asc", text: "Senha Crescente" },
        { value: "senha desc", text: "Senha Decrescente" },
        { value: "hora asc", text: "Hora Crescente" },
        { value: "hora desc", text: "Hora Decrescente" },
      ],
      options_entrega: [
        {
          value: { tp: "ent", v: "BALCÃO" },
          html: `<i class="fa fa-hands"></i> Balcão`,
        },
        {
          value: { tp: "ent", v: "BUSCAR" },
          html: `<i class="fa fa-store"></i> Buscar`,
        },
        {
          value: { tp: "ent", v: "ENTREGA" },
          html: `<i class="fa fa-motorcycle"></i> Entrega`,
        },

        {
          value: { tp: "prob", v: "1" },
          html: `<i class="fa fa-exclamation-triangle"></i> Problema`,
        },
        {
          value: { tp: "prob", v: "2" },
          html: `<i class="fa fa-exclamation-circle"></i> Correção`,
        },
        {
          value: { tp: "pgto", v: "" },
          html: `<i class="fa fa-hand-holding-usd"></i> Á Receber`,
        },
        {
          value: { tp: "ifood", v: "1" },
          html: `<img style='width: 12px' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAABLdJREFUWEftV2lsVUUYbVkKakHKjmUrW5AqRRZlUSgohEXWBkSQxQREEZEYCGuDJoRE1JBIAGOIhCVuaK2sGmiEqBRCSQOBCAbZBCOyxB1KJYdz7sztve9xb23f40d/vEkm9907M9+c+b7znW9eMpLSkVSVmwBW5Z5UlcE50U0AjJNCCQ/GS6GEBxMejNcD8a4P4WATVpc6tje1leYBPhuw17W90V2sQM1oK429Hrv2bl5mOwAgJ6d1AgY+CwyaANTPtItooHkPoP9YoG8OkN6N32U43lLJg6e0Bh7MBjo/Zffz7AYApJdm56KsZQ00npu/HPjtqvf93fX83vAuAGQkHhsO/Pk3UFICDH7ORs4cPAAgFzz/GrCjAFj3ofHeVL6rXfwVGD4ZGD0N6DXChsSlQn2+iwauR/Vb39zx6MPISwppCjBtrrF//QbQqb/9HgYwmfFv9wSQ+STQ7BHgoQHAj2eMgS07gA69aUC8rA20YMhFg6EE3UbfxVEZFj/5uz3tDJlEr0w0thzAGhdYHjxrEJA9BsjbaewfPwnc195SKhAgN27aBTh1FvjvFvA+PeiCc4Nbyu99RgGL3gR+ueSF/PI14MWFNE6u1u0IrN0IXPvDGz9/kd6fynEmWaOHgc15wA2G1N/yv7KH8yIRFWLx4WlvyYSXgSUrzPvNUmDWEuDx0cCGLZGG3bdLl4GMnsDWPcHjRUeBhkzAb/YHj+e+fQevowDS9eKf2j//Aq252bxl5v3cBaAms02EDmsXyFElT1grPga8sy58fNiU8gBanVu9wRg4cQq4py2w3Xpj3wHDq0+3h29w6AhQfDx8vOA7c9Cgpizu2I97NI5QBp8HmVXJ7N8eNMu37ibAdgT6k3lfRc+I/Ed+CAfw6lLg7M8hAP4Cct8KX1uWIJHa6gNI76R3J7F/N0ZeXwm07WNCrTZlDgHeb8gd1N7g/BQqwP6iO0dLbgITZwEDnokck6yI22qStQBd9QGkBEjNCw8DCpUyVZxQK6WRHsOMTCjLP/iE2X0aOHMe2MOwjZvhSUsmZSlvF3CaY1KD/K9N9ZHm1ekAfEapkpcLvgfGzyRltgFF3G/GAmsjsjJFJkm1FkD1loCeArPSEloVpAmBOfqmzjGVw8ZZVhPpWafsqYbaep2SwaRqxXfqZVINO27rumPLCnl17uXs5xd5D2RUFmuSjKgTyIq1JmTvbSI/uSjVFVHNE1AJbnQ95pgi0ZcC3G0I0JNZL+m6l3x2QMi21unp369CAKMLv2tANwwaHMtQpunyUF4N5ryurN+FPi5K0J3LhXszqvgF43+u/PKO25n+vUea6lJbodO1y/WCDqJ5KmWp9DTl6aMvvYRQFKppTrCXyrsRVfI/CUFKJw8WM4SsKAq7MtvpHMt4FHhlMXCSCeRva7imrE5X3Hsx/HGnx2rRe1+wZqodPQF8nG+kZ28hcIX12N82fm7mvqQaHdvVrJIe1OnpKYVrzlJWBV4AoptAStNyphuvdR9qelSFqOhFNwaAAinukYOp1DXxMucFk0AS4ja9LBiXo5yvK1yMN+8YAbo8Ut0U/9xLqUApUfzSE1tyuAeKE2DlCB+LFxMAY/Gaf02V9+BtXYcxa70TgB0AAAAASUVORK5CYII="> Ifood`,
        },

        {
          value: { tp: "online", v: "3" },
          html: `<i class="fa fa-globe"></i> Online`,
        },
        {
          value: { tp: "tipo_entrega", v: "3" },
          html: `<i class="fa fa-clipboard-check text-danger"></i> Á Confirmar`,
        },
        {
          value: { tp: "tipo_entrega", v: "0" },
          html: `<i class="fa fa-dot-circle text-warning"></i> Pendente`,
        },
        {
          value: { tp: "tipo_entrega", v: "1" },
          html: `<i class="fa fa-dot-circle text-info"></i> Rota`,
        },
        {
          value: { tp: "tipo_entrega", v: "2" },
          html: `<i class="fa fa-dot-circle text-success"></i> Entregue`,
        },
      ],
      fields: [
        { key: "senha", label: "Senha", sortable: true },
        { key: "tipo_entrega", label: "Entrega", sortable: true },
        { key: "sistema", label: "Sistema", sortable: true },
        {
          key: "cliente.Nome",
          label: "Cliente",
          sortable: true,
          class: "text-left",
        },
        { key: "cliente.Telefone", label: "Telefone", sortable: true },
        { key: "data", label: "Hora", sortable: true },
        { key: "tempo_entrega", label: "Previsão", sortable: true },
        { key: "vl_total", label: "Valor", sortable: true },
        {
          key: "pagamento",
          label: "Pagamento",
          sortable: true,
          sortDirection: "asc",
        },
      ],
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.carregar(false);
    }, 15000);
  },
  beforeDestroy() {
    try {
      clearInterval(this.timer);
    } catch {
      console.log("error destroy timer");
    }
    this.timer = null;
  },
  watch: {
    filtro: function () {
      this.filtrar();
      this.paginacao.pagina = 1;
    },
    filtro_senha: function () {
      this.filtrar();
      this.paginacao.pagina = 1;
    },
    filtro_agendamentos: function () {
      this.filtrar();
      this.paginacao.pagina = 1;
    },
    data: function () {
      this.carregar(true);
    },
    peds: function () {
      this.filtrar();
    },
    tipo_entrega_filtro: function () {
      this.filtrar();
    },
    paginacao: {
      deep: true,
      handler() {
        this.paginar();
      },
    },
    orderSelected: function () {
      this.paginar();
    },
  },
  methods: {
    paginar() {
      this.pedidosPaginado = this.pedidos
        .filter((x) => !x.agendamento || x.agendamento == "")
        .sort((a, b) => {
          return moment(a.data).isBefore(b.data) ? 1 : -1;
        })
        .sort((a, b) => {
          return !a.pagamento || a.pagamento == "" || a.pagamento < b.pagamento
            ? -1
            : 1;
        })
        .sort((a, b) => {
          if (this.orderSelected != "0") {
            if (this.orderSelected.indexOf("senha") >= 0) {
              return (
                (a.senha > b.senha ? -1 : 1) *
                (this.orderSelected.indexOf("asc") >= 0 ? -1 : 1)
              );
            }
            if (this.orderSelected.indexOf("hora") >= 0) {
              return (
                (moment(a.data).isBefore(b.data) ? 1 : -1) *
                (this.orderSelected.indexOf("asc") >= 0 ? -1 : 1)
              );
            }
          }
        })
        .sort((a, b) => {
          if (a.agendamento || b.agendamento) return -1;
          else return 1;
        })
        .slice(
          (this.paginacao.pagina - 1) * this.paginacao.registros,
          this.paginacao.pagina * this.paginacao.registros
        );

      this.pedidosAgendadoPaginado = this.pedidos
        .filter((x) => x.agendamento && x.agendamento !== "")
        .sort((a, b) => {
          return moment(a.data).isBefore(b.data) ? 1 : -1;
        })
        .sort((a, b) => {
          return !a.pagamento || a.pagamento == "" || a.pagamento < b.pagamento
            ? -1
            : 1;
        })
        .sort((a, b) => {
          if (this.orderSelected != "0") {
            if (this.orderSelected.indexOf("senha") >= 0) {
              return (
                (a.senha > b.senha ? -1 : 1) *
                (this.orderSelected.indexOf("asc") >= 0 ? -1 : 1)
              );
            }
            if (this.orderSelected.indexOf("hora") >= 0) {
              return (
                (moment(a.data).isBefore(b.data) ? 1 : -1) *
                (this.orderSelected.indexOf("asc") >= 0 ? -1 : 1)
              );
            }
          }
        })
        .sort((a, b) => {
          if (a.agendamento || b.agendamento) return -1;
          else return 1;
        })
        .slice(
          (this.paginacao.pagina - 1) * this.paginacao.registros,
          this.paginacao.pagina * this.paginacao.registros
        );
    },
    confirmarPedido(item) {
      this.abrirPedido([item]);
    },
    CloseOnSave() {
      this.carregar(false);
      this.$bvModal.hide("modal-pedido");
      try {
        this.$refs.tbCaixas.clearSelected();
      } catch {
        console.log();
      }
    },
    closeModalEdit() {
      try {
        this.$refs.tbCaixas.clearSelected();
      } catch {
        console.log();
      }
    },
    async receber(row) {
      this.resumed = true;
      this.$forceUpdate();
      await this.$nextTick();
      this.abrirPedido([row]);
    },
    abrirPedido(row) {
      if (row && row.length > 0) {
        this.pedido = row[0];
        // console.log(this.pedido);
        if (this.pedido.sistema == "ONLINE") {
          this.$bvModal.show("modal-pedido-online");
        } else {
          this.$bvModal.show("modal-pedido");
        }
      }
      // console.log(row);
    },
    async carregar(withLoad = true) {
      if (withLoad) {
        this.loading = true;
        this.paginacao.pagina = 1;
        this.pedidosPaginado = [];
        this.pedidosAgendadoPaginado = [];
      }
      try {
        this.peds = (
          await PedidoLib.get({
            data: this.data,
            cod_empresa: this.empresa_selecionada.cod_empresa,
            semMesa: true,
            comAgendamentos: true,
          })
        ).data
          .sort((a, b) => {
            return a.pagamento - b.pagamento ? 1 : -1;
          })
          .sort((a, b) => (a.cod_pedido > b.cod_pedido ? -1 : 1));
        // .map((it) => {
        //   return {
        //     ...it,
        //     _rowVariant:
        //       !it.pagamento || it.pagamento == "" || it.status == 2
        //         ? "warning"
        //         : it.status == 3
        //         ? "info"
        //         : it.status == 4
        //         ? "success"
        //         : status == 0
        //         ? "danger"
        //         : "default",
        //   };
        // });
        this.ultUpd = moment();
        // console.log("pedidos carregados");
        this.paginar();

        //console.log(this.peds);
      } catch (e) {
        console.log("erro on update", e);
      }

      this.loading = false;
    },
    async filtrar() {
      // console.log("filtrando", this.peds);
      this.pedidos = this.peds
        .filter((x) =>
          this.filtro_senha && this.filtro_senha != ""
            ? x.senha.toString() === this.filtro_senha.toString()
            : this.filtro && this.filtro != ""
            ? JSON.stringify(x)
                .toLowerCase()
                .indexOf(this.filtro.toLowerCase()) >= 0
            : true
        )
        .filter(
          (x) =>
            (this.filtro_agendamentos === true
              ? x.agendamento != ""
              : !x.agendamento || x.agendamento === "") &&
            1 == 1 &&
            (this.tipo_entrega_filtro.filter((z) => z.tp == "tipo_entrega")
              .length > 0
              ? this.tipo_entrega_filtro
                  .filter((z) => z.tp == "tipo_entrega")
                  .map((ts) => {
                    if (ts.v == "0") {
                      return x.status <= 2;
                    } else if (ts.v == "3") {
                      return x.status <= 1;
                    } else if (ts.v == "1") {
                      return x.status == 3;
                    } else if (ts.v == "2") {
                      // console.log([4].includes(x.status))
                      return x.status == 4;
                    }
                  })
                  .includes(true)
              : 1 == 1) &&
            (this.tipo_entrega_filtro.filter((z) => z.tp == "prob").length > 0
              ? x.pedido_erro == 1
              : 1 == 1) &&
            (this.tipo_entrega_filtro.filter((z) => z.tp == "ifood").length > 0
              ? x.sistema == "IFOOD"
              : 1 == 1) &&
            (this.tipo_entrega_filtro.filter((z) => z.tp == "ent").length > 0
              ? this.tipo_entrega_filtro
                  .filter((z) => z.tp == "ent")
                  .map((it) => {
                    return it.v;
                  })
                  .includes(x.tipo_entrega)
              : 1 == 1) &&
            (this.tipo_entrega_filtro.filter((z) => z.tp == "pgto").length > 0
              ? !x.pagamento || x.pagamento == ""
              : 1 == 1) &&
            (this.tipo_entrega_filtro.filter((z) => z.tp == "online").length > 0
              ? x.sistema == "ONLINE" ||
                x.sistema == "ONPEDIDO" ||
                x.sistema == "IFOOD"
              : 1 == 1)
        );
      this.paginar();
    },
    selecionarEmpresa(emp) {
      this.empresa_selecionada = emp;
      this.carregar();
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/assets/scss/pedidos.scss"
</style>
